import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Button, Col, Flex, Row, Typography,} from 'antd';
import {PlusCircleOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import FlightInformation from 'components/FlightInfrormation';
import Option from './components/Option';
import {FlightV2, generateFlightTimes, Route} from 'utils/flight';
import validation from '../../assets/images/options/validation.webp';
import searReservation from '../../assets/images/options/seat_reservation.jpg';
import insurance from '../../assets/images/options/insurance_allianz.jpg';
import additionalBaggage from '../../assets/images/options/additional_baggage.jpg';
import specialMeals from '../../assets/images/options/special_meal.jpg';
import sportEquipment from '../../assets/images/options/sports_equipment.jpg';
import climate from '../../assets/images/options/my_climate.jpg';
import TicketInfo from "./components/TicketInfo";
import IframeDrawer from "./components/IframeDrawer";
import {useLocation, useNavigate} from "react-router-dom";
import {Airport, FlightTicketExpanded, ValidationRequest, ValidationResultInfo} from "../../utils/checkin";
import BookingInfoDrawer from "./components/BookingInfoDrawer";
import {useContextSelector} from "use-context-selector";
import {AppContext, AppContextType} from "../../AppContext";
import FlightInformationTwoDirect from "../../components/FlightInfrormationTwoDirect";
import dayjs, {Dayjs} from "dayjs";
import OutboundInfo from "../../components/OutboundInfo";
import {Pax} from "../../utils/booking";
import './MyOptionsRetrieveScreen.css';


export default function MyOptionsRetrieveScreen() {
    const {state: {flight_tickets, available_directions: available_directions, validation_request}}: {
        state: { flight_tickets: FlightTicketExpanded[], available_directions: string[], validation_request: ValidationRequest }
    } = useLocation();
    const {state} = useLocation();
    const [flight_ticket] = flight_tickets;
    const {clear} = useContextSelector(AppContext, (c: AppContextType) => c.booking);
    const {onGetAirport} = useContextSelector(AppContext, (c: AppContextType) => c.checkin);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [
        iframeError,
        setIframeError
    ] = useState<object | null>(null);
    const [
        openTicket,
        setOpenTicket
    ] = useState<FlightTicketExpanded | null>(null);
    const [
        validationResult,
        setValidationResult
    ] = useState<ValidationResultInfo | null>(null);
    const [
        airports,
        setAirports
    ] = useState<Record<string, Airport>>({});

    const validate = useCallback(() => {
        if (iframeError) {
            navigate('/my-booking');
        } else {
            setOpen(true);
        }
        setOpen(true);
    }, [iframeError]);

    const options = useMemo(() => {
        return [
            {
                title: t('MyOptionsRetrieveScreen.validation'),
                img: validation,
                content: (
                    <>
                        <Typography.Text>{t('MyOptionsRetrieveScreen.validationDescription')}</Typography.Text>
                    </>
                ),
                actions: [
                    <Button
                        type="link"
                        disabled={available_directions?.length === 0}
                        onClick={validate}
                        style={{fontSize: 16}}
                        icon={<PlusCircleOutlined/>}
                        iconPosition='end'> {t('MyOptionsRetrieveScreen.validate')}</Button>
                ]
            },
            {
                title: t('MyOptionsRetrieveScreen.seatReservation'),
                img: searReservation,
                content: (
                    <>
                        <Typography.Text>{t('MyOptionsRetrieveScreen.oneWay')}</Typography.Text>
                    </>
                ),
                actions: [<Button type="link" style={{fontSize: 16}} icon={< PlusCircleOutlined/>}
                                  iconPosition='end'> {t('MyOptionsRetrieveScreen.add')}</Button>]
            },
            {
                title: t('MyOptionsRetrieveScreen.insurance'),
                img: insurance,
                content: <Typography.Text>{t('MyOptionsRetrieveScreen.insuranceDesc')}</Typography.Text>,
                actions: [<Button type="link" style={{fontSize: 16}} icon={<PlusCircleOutlined/>}
                                  iconPosition='end'>{t('MyOptionsRetrieveScreen.add')}</Button>]
            },
            {
                title: t('MyOptionsRetrieveScreen.baggage'),
                img: additionalBaggage,
                content: <Typography.Text>{t('MyOptionsRetrieveScreen.baggageDesc')}</Typography.Text>,
                actions: [<Button type="link" style={{fontSize: 16}} icon={<PlusCircleOutlined/>}
                                  iconPosition='end'>{t('MyOptionsRetrieveScreen.add')}</Button>]
            },
            {
                title: t('MyOptionsRetrieveScreen.specialMeals'),
                img: specialMeals,
                content: <Typography.Text>{t('MyOptionsRetrieveScreen.specialMealsDesc')}</Typography.Text>,
                actions: [<Button type="link" style={{fontSize: 16}} icon={<PlusCircleOutlined/>}
                                  iconPosition='end'>{t('MyOptionsRetrieveScreen.add')}</Button>]
            },
            {
                title: t('MyOptionsRetrieveScreen.sportsEquipment'),
                img: sportEquipment,
                content: <Typography.Text>{t('MyOptionsRetrieveScreen.sportsEquipmentDesc')}</Typography.Text>,
                actions: [<Button type="link" style={{fontSize: 16}} icon={<PlusCircleOutlined/>}
                                  iconPosition='end'>{t('MyOptionsRetrieveScreen.add')}</Button>]
            },
            {
                title: t('MyOptionsRetrieveScreen.climate'),
                img: climate,
                content: <Typography.Text>{t('MyOptionsRetrieveScreen.climateDesc')}</Typography.Text>,
                actions: [<Button type="link" style={{fontSize: 16}} icon={<PlusCircleOutlined/>}
                                  iconPosition='end'>{t('MyOptionsRetrieveScreen.add')}</Button>]
            },
        ]
    }, [t, validate]);

    const groupedRoutes = useMemo(() => {
        return flight_tickets?.[0].routes.reduce((acc, route) => {
            const flightDate = route.flight_date;

            if (!acc[flightDate]) {
                acc[flightDate] = [];
            }

            acc[flightDate].push({
                airline: route.airline,
                flight_number: route.flight_number,
                flight_departure_time: route.flight_departure_time ?? '',
                flight_arrival_time: route.flight_arrival_time ?? '',
                destination: route.flight_destination,
                destination_name: route.flight_destination_name ?? '',
                origin: route.flight_origin,
                origin_name: route.flight_origin_name ?? '',
                id: '',
            });
            return acc;
        }, {} as Record<string, Route[]>);
    }, [flight_tickets]);

    const {adults, childs} = useMemo(() => {
        if (!flight_tickets.length) {
            return {
                adults: 0,
                childs: 0
            }
        }

        let adults = 0;
        let childs = 0;

        flight_tickets.forEach((ticket) => {
            switch (ticket.booking?.type?.toLowerCase()) {
                case 'adult':
                    return adults += 1
                case 'child':
                    return childs += 1
                default:
                    return
            }
        })

        return {
            adults: adults,
            childs: childs
        }
    }, [flight_tickets]);

    const onIframeValidationResult = useCallback((result: any) => {
        console.log('onIframeValidationResult', result);
        setValidationResult(result);
    }, []);

    const onShowInfo = useCallback((ticket: FlightTicketExpanded) => {
        console.log('onIframeValidationResult', ticket, validationResult);
        setOpenTicket(ticket);
    }, [validationResult]);

    const onHideInfo = useCallback(() => {
        setOpenTicket(null);
    }, []);

    const onIframeClose = useCallback(() => {
        // setIframeError(null);
        setOpen(false);
        // clear();
    }, []);

    const onIframeError = useCallback((err: object) => {
        setIframeError(err);
        clear();
    }, []);

    const isTwoDirect = useMemo(() => {
        return Object.keys(groupedRoutes).length === 2;
    }, [groupedRoutes]);

    const getFromTo = useCallback((routes: Route[]) => {
        const tmp: {
            [key: string]: {
                source: 'origin' | 'destination',
                counter: number
            }
        } = {};

        routes.forEach((route) => {
            tmp[route.origin] = {
                source: 'origin',
                counter: tmp[route.origin]?.counter ? tmp[route.origin].counter + 1 : 1
            };

            tmp[route.destination] = {
                source: 'destination',
                counter: tmp[route.destination]?.counter ? tmp[route.destination].counter + 1 : 1
            };
        });

        return Object.keys(tmp)
            .filter(key => tmp[key].counter === 1)
            .reduce((acc, key) => ({...acc, [tmp[key].source]: key}), {}) as { origin: string, destination: string };
    }, []);

    const outbound = useMemo(() => {
        const date = Object.keys(groupedRoutes)
            .reduce((a, b) => a < b ? a : b);
        const routes = groupedRoutes[date] as Route[]; // routes

        const {origin, destination} = getFromTo(routes);

        console.log('outbound routes', routes, origin, destination)

        return {
            routes: generateFlightTimes(routes
                .map((r) => ({
                    ...r,
                    origin_name: airports[r.origin]?.name,
                    destination_name: airports[r.destination]?.name,
                    id: ''})) as Route[]),
            originCountryName: airports[origin]?.countryCode || origin,
            destinationCountryName: airports[destination]?.countryCode || destination,
            date: dayjs(date) as Dayjs
        }
    }, [airports, getFromTo, groupedRoutes]);

    const inbound = useMemo(() => {
        const date = Object.keys(groupedRoutes)
            .reduce((a, b) => a > b ? a : b);
        const routes = groupedRoutes[date] as Route[]; // routes
        const {origin, destination} = getFromTo(routes);

        return {
            routes: generateFlightTimes(routes
                .map((r) => ({
                    ...r,
                    origin_name: airports[r.origin]?.name,
                    destination_name: airports[r.destination]?.name,
                    id: ''})) as Route[]),
            originCountryName: airports[origin]?.countryCode || origin,
            destinationCountryName: airports[destination]?.countryCode || destination,
            date: dayjs(date) as Dayjs
        }
    }, [airports, groupedRoutes]);

    const flightOutbound = useMemo(() => {
        return (
            {
                id: 'outbound',
                classes: [flight_ticket.booking?.service_class],
                date: outbound.date.format('YYYY-MM-DD'),
                route: outbound.routes
            } as FlightV2
        )
    }, [outbound, flight_ticket]);

    const flightInbound = useMemo(() => {
        if (inbound.date.isSame(outbound.date, 'day')) {
            return null;
        }
        return (
            {
                id: 'inbound',
                classes: [flight_ticket.booking?.service_class],
                date: inbound.date.format('YYYY-MM-DD'),
                route: inbound.routes
            } as FlightV2
        )
    }, [inbound, outbound, flight_ticket]);

    const passengers = useMemo(() => {
        return ({
            adults: [{
                type: 'adult',
                fn: flight_ticket.passenger_firstname,
                ln: flight_ticket.passenger_lastname,
                sex: flight_ticket.booking?.sex,
                seat_number: flight_ticket.booking?.seat_number,
                flyClass: flight_ticket.booking?.service_class
            }],
            childs: [],
            infants: []
        }) as unknown as Record<'adults' | 'childs' | 'infants', Pax[]>
    }, [flight_ticket]);

    console.log('[MyOptionsRetrieveScreen ]============ flight_tickets', state);

    useEffect(() => {
        const date = Object.keys(groupedRoutes)
            .reduce((a, b) => a < b ? a : b);
        const routes = groupedRoutes[date] as Route[]; // routes

        const { origin, destination} = getFromTo(routes);

        onGetAirport([origin, destination]).then((airports) => {
            setAirports(p => {
               console.log('[MyOptionsRetrieveScreen] airports', airports);
               return airports.reduce((acc, airport) => {
                   return {...acc, [airport.iata]: airport};
               }, {});
            });
        })
    }, [groupedRoutes]);

    return (
        <div className='MyOptionsRetrieveScreen'>
            <Flex vertical>

                {
                    isTwoDirect ? (
                        <FlightInformationTwoDirect
                            outbound={outbound}
                            inbound={inbound}
                            counts={{adults, childs}}
                            mode="white"
                            showNavigation={false}
                        />
                    ) : (
                        (Object.values(groupedRoutes) || []).map((routes, idx) => (
                            <FlightInformation
                                key={idx}
                                routes={routes}
                                originCountryName={routes[0]?.origin_name ?? ''}
                                destinationCountryName={routes[0]?.destination_name ?? ''}
                                date={flight_tickets[0].routes[0].flight_date}
                                counts={{
                                    adults: adults,
                                    childs: childs,
                                }}
                                mode="white"
                                showNavigation={false}
                            />
                        ))
                    )
                }

                <OutboundInfo
                    flight={flightOutbound as FlightV2}
                    date={outbound.date}
                    counts={{adults: 1, childs: 0}}
                    passengers={passengers}
                    mode="white"
                    type={'outbound'}
                    showNavigation={false}
                />

                {
                    flightInbound && (
                        <OutboundInfo
                            flight={flightInbound as FlightV2}
                            date={inbound.date}
                            counts={{adults: 1, childs: 0}}
                            passengers={passengers}
                            mode="white"
                            type={'inbound'}
                            showNavigation={false}
                        />
                    )
                }

                <Typography.Title level={5}>
                    {t('MyOptionsRetrieveScreen.passengers')}
                </Typography.Title>
                {
                    flight_tickets.map((ticket, idx) => (
                        <TicketInfo
                            key={idx}
                            ticket={ticket}
                            onShowInfo={onShowInfo}
                            valid={validationResult?.lastName === ticket.passenger_lastname || !!ticket?.validation_info_list?.length}
                        />
                    ))
                }
            </Flex>
            <Row gutter={[16, 16]}>
                {
                    options.map((option, idx) => (
                        <Col span={idx === 0 ? 24 : 12} key={idx}>
                            <Option
                                title={option.title}
                                img={option.img}
                                content={option.content}
                                actions={option.actions}
                            />
                        </Col>
                    ))
                }
            </Row>
            <IframeDrawer
                validation_request={validation_request}
                open={open}
                direction={available_directions?.length === 1 ? available_directions[0] : ''}
                onClose={onIframeClose}
                onError={onIframeError}
                onIframeValidationResult={onIframeValidationResult}
            />
            {
                openTicket && (
                    <BookingInfoDrawer
                        ticket={openTicket}
                        validationRequest={validation_request}
                        validationResult={validationResult}
                        close={onHideInfo}
                    />
                )
            }
        </div>
    )
}
