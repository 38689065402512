
import { useMemo } from 'react'
import { Flex, Typography } from 'antd'
import FlightInfo from 'components/FlightInfo'
import { FlightTicketRoute, Ticket } from 'utils/checkin';
import { get2D, getDuration, getMS } from 'utils/date';
import { useTranslation } from 'react-i18next';
import { Route } from 'utils/flight';

import './ShortFlightInfo.css';

export interface ShortFlightInfoProps {
    ticket?: Ticket | undefined,
    routes?: FlightTicketRoute[];
    passengerInfo?: boolean,
    transit?: number;
}

export default function ShortFlightInfo({ ticket, routes, passengerInfo, transit }: ShortFlightInfoProps) {
    const { t } = useTranslation();

    const bookingNumber = useMemo(() => {
        if (!ticket?.identifiers) {
            return 'N/a'
        }

        return ticket?.identifiers.map((identifier: string) => {
            return identifier.split(":")[1];
        })
            .join(", ");
    }, [ticket]);

    const groupedRoutes = useMemo(() => {
        if (!routes) {
            return null
        }

        return routes.reduce((acc, route) => {
            const flightDate = route.flight_date;

            if (!acc[flightDate]) {
                acc[flightDate] = [];
            }

            acc[flightDate].push({
                airline: route.airline,
                flight_number: route.flight_number,
                flight_departure_time: route.flight_departure_time ?? '',
                flight_arrival_time: route.flight_arrival_time ?? '',
                destination: route.flight_destination,
                destination_name: route.flight_destination_name ?? '',
                origin: route.flight_origin,
                origin_name: route.flight_origin_name ?? '',
                flight_date: route.flight_date ?? '',
                id: '',
            });
            return acc;
        }, {} as Record<string, Route[]>);
    }, [routes]);

    const flightInfo = useMemo(() => {
        if (!groupedRoutes) {
            return null
        }

        return Object.values(groupedRoutes || [])?.map((route: Route[]) => {
            if (route.length > 1) {
                const { hours, minutes } = getDuration(getMS(route[route.length - 1]?.flight_arrival_time || '00:00') - getMS(route[0]?.flight_departure_time || '00:00'));
                const duration = `${get2D(hours)}:${get2D(minutes)}`
                return ({
                    origin: route[0]?.origin || 'N/a',
                    destination: route[route.length - 1]?.destination || 'N/a',
                    date: route[0]?.flight_date || 'N/a',
                    departureAt: route[0]?.flight_departure_time || '9:40',
                    arrivalAt: route[route.length - 1]?.flight_arrival_time || '12:30',
                    duration: duration,
                    transit: route.length
                })
            } else {
                const { hours, minutes } = getDuration(getMS(route[0]?.flight_arrival_time || '00:00') - getMS(route[0]?.flight_departure_time || '00:00'));
                const duration = `${get2D(hours)}:${get2D(minutes)}`
                return ({
                    origin: route[0]?.origin || 'N/a',
                    destination: route[0]?.destination || 'N/a',
                    date: route[0]?.flight_date || 'N/a',
                    departureAt: route[0]?.flight_departure_time || '9:40',
                    arrivalAt: route[0]?.flight_arrival_time || '12:30',
                    duration: duration,
                    transit: 0
                })
            }
        })
    }, [groupedRoutes]);

    return (
        <Flex className='ShortFlightInfo' vertical gap={5}>
            <Flex>
                <Typography className='ShortFlightInfo-Text bold'>{t('Ticket.flightNumber')}: {ticket?.flight_number ?? 'N/a'}</Typography>
            </Flex>
            <Flex>
                <Typography className='ShortFlightInfo-Text bold'>{t('Ticket.bookingNumber')}: {bookingNumber}</Typography>
            </Flex>
            {
                !!flightInfo && flightInfo.map((info, idx) => (
                    <Flex key={idx} className='ShortFlightInfo-Flight'>
                        <FlightInfo {...info} />
                    </Flex>
                ))
            }
            {
                passengerInfo && (
                    <Flex justify='space-between'>
                        <Flex vertical align='start'>
                            <Typography.Text style={{ fontSize: 16 }} strong>{t('PassengerForm.firstName')}</Typography.Text>
                            <Typography.Text>{ticket?.passenger_firstname ?? t('N/a')}</Typography.Text>
                        </Flex>
                        <Flex vertical align='end'>
                            <Typography.Text style={{ fontSize: 16 }} strong>{t('PassengerForm.lastName')}</Typography.Text>
                            <Typography.Text>{ticket?.passenger_lastname ?? t('N/a')}</Typography.Text>
                        </Flex>
                    </Flex>
                )
            }
        </Flex>
    )
}
