import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useContextSelector } from 'use-context-selector';
import { Flex, Row, Col, Button, notification, Radio, theme } from 'antd';
import { useNavigate } from 'react-router-dom';
import CountrySelect from 'components/CountrySelect';
import PassengerSelect from 'components/PassengerSelect';
import PassengerCounter from 'components/PassengerCounter';
import { FlightPoint, FlightV2 } from 'utils/flight';
import Label from 'components/Label';
import Loader from 'components/Loader';
import { AppContext, AppContextType } from 'AppContext';
import usePrevious from 'hooks/usePrevious';
import airlineUpIcon from '../../assets/images/airline_up.svg';
import airlineDownIcon from '../../assets/images/airline_down.svg';
import Portal from 'components/Portal';
import { IDS } from 'helpers/constants';
import { Pax } from 'utils/booking';
import './InitBookingScreen.css';
import { CheckboxChangeEvent } from "antd/es/checkbox";

const { useToken } = theme;

const NOTIFICATION_KEY = 'flights_notification';

export default function InitBookingScreen() {
    const { t } = useTranslation();
    const { token } = useToken();
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const {
        getFlights,
        getDeparturesList,
        getArrivalsList,
        clear: clearFlight,
        flights
    } = useContextSelector(AppContext, (c: AppContextType) => c.flight);
    const {
        setDeparture,
        setArrival,
        setShowCalendar,
        setCounts,
        isOneWay,
        setIsOneWay,
        setPassengers,
        arrival,
        departure,
        showCalendar,
    } = useContextSelector(AppContext, (c: AppContextType) => c.booking);
    const { key, provider } = useContextSelector(AppContext, (c: AppContextType) => c.configAirline);
    const [origin, setOrigin] = useState<FlightPoint | null>(departure);
    const [destination, setDestination] = useState<FlightPoint | null>(arrival);
    const [adults, setAdults] = useState<number>(1);
    const [kids, setKids] = useState<number>(0);
    const [infants, setInfants] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [disableDiretcion, setDisableDirection] = useState<boolean>(false);

    const prevOrigin = usePrevious(origin);

    useEffect(() => {
        if (!key && !provider) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [key, provider]);

    useEffect(() => {
        if (
            (origin?.iata === 'FRA' && destination?.iata === 'PVG') ||
            (origin?.iata === 'VIE' && (destination?.iata === 'NYC' || destination?.iata === 'JFK')) ||
            (origin?.iata === 'BSL' && destination?.iata === 'LCA') ||
            (origin?.iata === 'PRN' && destination?.iata === 'BER')
        ) {
            setIsOneWay(true);
            setDisableDirection(true);
        }
    }, [destination?.iata, disableDiretcion, origin?.iata, setIsOneWay]);

    const route = [
        { label: t('SearchFlight.roundTrip'), value: false },
        { label: t('SearchFlight.oneWay'), value: true }
    ]

    const passengerCounter = useMemo(() => {
        return adults + kids + infants
    }, [adults, infants, kids]);

    const onSelectOrigin = useCallback((value: FlightPoint | null) => {
        setOrigin(value);
        setDeparture(value);
        showCalendar && setShowCalendar(false);
        !!flights.length && clearFlight();
        if (destination) {
            setDestination(null);
            setArrival(null);
        }
    }, [setDeparture, destination, setArrival, showCalendar, flights]); //eslint-disable-line

    const onSelectDestination = useCallback((value: FlightPoint | null) => {
        showCalendar && setShowCalendar(false);
        !!flights.length && clearFlight();
        setDestination(value);
        setArrival(value);
        setLoading(true);
        getFlights({ origin: value?.iata ?? '', destination: origin?.iata ?? '' })
        .then((res) => {
            if (!res.length) {
                setIsOneWay(true)
                setDisableDirection(true);
            }
            setLoading(false);
        })
        .catch((err) => {
            console.log('[InitBookingScreen] search back route error', err);
            setLoading(false);
        })
    }, [setArrival, setDisableDirection, setIsOneWay, showCalendar, flights, origin]); //eslint-disable-line

    const onSearchFlights = useCallback(() => {
        setLoading(true);
        setShowCalendar(false);
        setDeparture(origin);
        setArrival(destination);
        getFlights({ origin: origin?.iata ?? '', destination: destination?.iata ?? '' })
            .then((resp: FlightV2[]) => {
                setLoading(false);
                if (!resp.length) {
                    api.warning({
                        key: NOTIFICATION_KEY,
                        message: t('warning'),
                        description: t('noFlightsAvailable'),
                        placement: 'topRight',
                    });
                } else {
                    setCounts({
                        adults: adults,
                        childs: kids,
                        infants: infants
                    })
                    //TODO remove after outbound step is ready
                    setPassengers({
                        adults: Array.from(
                            { length: adults },
                            () => ({}),
                        ) as Pax[],
                        childs: Array.from(
                            { length: kids },
                            () => ({}),
                        ) as Pax[],
                        infants: Array.from(
                            { length: infants },
                            () => ({}),
                        ) as Pax[],
                    }, () => { })
                    setShowCalendar(true);
                }
            })
            .catch((err) => {
                console.log('[InitBookingScreen] onSearchFlights', err);
                setLoading(false);
                api.error({
                    key: NOTIFICATION_KEY,
                    message: t('error'),
                    description: t([err.message, 'error_oops']),
                    placement: 'topRight',
                });
            })
    }, [getFlights, navigate, origin, destination, adults, kids, infants, setShowCalendar]); // eslint-disable-line

    const onChangeBackFlight = useCallback((e: CheckboxChangeEvent) => {
        setIsOneWay(e.target.value);
    }, [setIsOneWay]);

    return (
        <>
            <div style={{ margin: '10px', width: '100%' }}>
                <Flex style={{ marginBottom: 20 }}>
                    <Radio.Group
                        options={route}
                        defaultValue={isOneWay}
                        value={isOneWay}
                        optionType="button"
                        onChange={onChangeBackFlight}
                        disabled={disableDiretcion}
                    />
                </Flex>
                <Row gutter={[16, 8]}>
                    <Col xs={24} sm={24} md={6}>
                        <Flex vertical style={{ background: '#FFF', padding: 5 }}>
                            <Label text={t('SearchFlight.from')} styles={{ opacity: 0.55, marginLeft: '10px', fontSize: '1rem' }} />
                            <CountrySelect
                                name='origin'
                                placeholder={t('SearchFlight.yourOrigin')}
                                value={origin}
                                icon={<img src={airlineUpIcon} alt='' />}
                                size='large'
                                onSelect={onSelectOrigin}
                                style={{ width: '100%' }}
                                search={getDeparturesList}
                            />
                        </Flex>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                        <Flex vertical style={{ background: '#FFF', padding: 5 }}>
                            <Label text={t('SearchFlight.to')} styles={{ opacity: 0.55, marginLeft: '10px', fontSize: '1rem' }} />
                            <CountrySelect
                                name='departure'
                                placeholder={t('SearchFlight.yourDestination')}
                                value={destination}
                                icon={<img src={airlineDownIcon} alt='' />}
                                size='large'
                                disabled={!origin}
                                onSelect={onSelectDestination}
                                style={{ width: '100%' }}
                                forceUpdate={prevOrigin !== origin}
                                search={() => getArrivalsList(origin?.iata ?? '')}
                            />
                        </Flex>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                        <Flex vertical style={{ background: '#FFF', padding: 5 }}>
                            <Label text={t('SearchFlight.passengers')} styles={{ opacity: 0.55, marginLeft: '10px', fontSize: '1rem' }} />
                            <PassengerSelect
                                value={passengerCounter}
                            >
                                <PassengerCounter
                                    title={t('adults')}
                                    counter={adults}
                                    onChange={setAdults}
                                    minValue={1}
                                />
                                <PassengerCounter
                                    title={t('kids')}
                                    counter={kids}
                                    onChange={setKids}
                                />
                            </PassengerSelect>
                        </Flex>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                        <Flex gap="middle" style={{ height: '100%' }}>
                            <Button
                                className='Find-btn'
                                type='primary'
                                size='large'
                                onClick={onSearchFlights}
                                style={{ background: "#FFF", color: token.colorPrimary, height: '100%', borderRadius: 2 }}
                                disabled={!origin || !destination || !adults}
                            >
                                {t('SearchFlight.findFlights')}
                            </Button>
                        </Flex>
                    </Col>
                    {/* <Col xs={24} sm={24} md={24}>
                        <Checkbox
                            style={{ color: "#fff" }}
                            checked={!isOneWay}
                            onChange={onChangeBackFlight}>{t('returnFlight')}</Checkbox>
                    </Col> */}
                </Row>
            </div>
            {
                loading && <Portal id={IDS.loader}>
                    <Loader />
                </Portal>
            }
            {contextHolder}
        </>
    );
}
