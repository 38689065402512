import { useCallback, useEffect, useMemo, useState } from 'react';
import { useContextSelector } from 'use-context-selector';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Drawer, Flex, Typography, theme } from 'antd';
import InfoBlock from 'components/InfoBlock';
import { AppContext, AppContextType } from 'AppContext';
import BoardingPass from './components/BoardingPass';
import { Airport, FlightTicketExpanded, FlightTicketRoute, getRoutesByDirection, PaxList } from 'utils/checkin';
import './Boarding.css';
import { BookingTickets } from 'utils/mybooking';

const { useToken } = theme;

export default function Boarding() {
    const { t } = useTranslation();
    const { token } = useToken();
    const navigate = useNavigate();
    const { iframeValidationResult, groupedTicket, onGetAirport, validationDirection } = useContextSelector(AppContext, (c: AppContextType) => c.checkin);
    const { searchBookingsInCheckin, booking } = useContextSelector(AppContext, (c: AppContextType) => c.booking);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [showBooking, setShowBooking] = useState<boolean>(false);
    const [selectedTicket, setSelectedTicket] = useState<PaxList>();
    const [airports, setAirports] = useState<Record<string, Airport>>({});

    useEffect(() => {
        if (!selectedTicket) {
            return
        }

        const ticket = groupedTicket[0].validation_request?.paxList.find((ticket) => ticket === selectedTicket);
        const pnl = groupedTicket[0].validation_request?.pnr;

        if (pnl && ticket?.lastName) {
            setLoading(true);
            searchBookingsInCheckin(pnl, ticket?.lastName as string)
                .then((pnl: BookingTickets) => {
                    console.log('[Boarding] pnl:', pnl);

                    const iataArray = Array.from(
                        new Set(
                            pnl.flight_tickets.flatMap((ticket: FlightTicketExpanded) =>
                                ticket.routes.flatMap((route: FlightTicketRoute) => [route.flight_origin, route.flight_destination])
                            )
                        )
                    )
                    onGetAirport(iataArray).then((airports) => {
                        setAirports(p => {
                            return airports.reduce((acc, airport) => {
                                return { ...acc, [airport.iata]: airport };
                            }, {});
                        });
                        setLoading(false);
                    })
                        .catch((err) => {
                            console.log('[Boarding] searchBookings -> onGetAirport', err)
                            setLoading(false);
                        })
                })
                .catch((error) => {
                    console.log('[Boarding] searchBookings', error);
                    setError(error.message);
                    setLoading(false);
                })
        }
    }, [groupedTicket, selectedTicket, searchBookingsInCheckin]);

    const flightData = useMemo(() => {
        if (!groupedTicket.length) {
            return null
        }

        const ticket = groupedTicket[0]?.flight_tickets[0]

        if (validationDirection) {
            const routes = getRoutesByDirection(ticket, validationDirection).routes;
            return routes.map((route) => ({
                flight_number: route?.flight_number,
                departure_airport: route?.flight_origin,
                destination_airport: route?.flight_destination,
                departure: !!route?.flight_departure_time 
                    ? `${route?.flight_date} ${route?.flight_departure_time}` 
                    : route?.flight_date
            }))
        } else {
            return ticket.routes.map((route) => ({
                flight_number: route.flight_number,
                departure_airport: route.flight_origin,
                destination_airport: route.flight_destination,
                departure: !!route?.flight_departure_time 
                    ? `${route?.flight_date} ${route?.flight_departure_time}`
                    : route?.flight_date
            }))
        }

    }, [groupedTicket, validationDirection]);

    const passengers = useMemo(() => {
        if (!groupedTicket.length) {
            return null
        }

        return groupedTicket[0].validation_request?.paxList.map((ticket) => ({
            passenger: `${ticket.firstName} / ${ticket.lastName}`,
            ticket_number: ticket.eTicket,
            boarding_pass: <Button
                disabled={!iframeValidationResult.some(result => result.eTicket === ticket.eTicket)}
                onClick={() => { setSelectedTicket(ticket); setShowBooking(true) }}
            >
                {t('InfoBlock.show')}
            </Button>
        }))

    }, [groupedTicket, t, iframeValidationResult]);

    const getContainer = useCallback(() => {
        const container = document.getElementById('Wrapper');
        return container ? container : document.body;
    }, []);

    return (
        <>
            <Flex vertical className='Boarding'>
                <Typography.Paragraph className='Boarding-Text'>
                    {t('Final.checkInFinished')}
                </Typography.Paragraph>
                {
                    flightData && (
                        <InfoBlock
                            data={flightData}
                        />
                    )
                }
                {
                    passengers && (
                        <InfoBlock
                            data={passengers}
                        />
                    )
                }
                <Typography.Paragraph className='Boarding-Text'>
                    {t('Final.responsible')}
                </Typography.Paragraph>
                <Flex justify='flex-end'>
                    <Button
                        type='primary'
                        size='large'
                        onClick={() => navigate('/')}
                    >
                        {t('close')}
                    </Button>
                </Flex>
            </Flex>
            <Drawer
                rootClassName='BoardingPass'
                open={showBooking}
                placement='bottom'
                title={<Typography style={{ fontSize: 24, color: '#FFF' }}>{t('Final.boardingPass')}</Typography>}
                onClose={() => setShowBooking(false)}
                style={{ height: '100vh' }}
                styles={{
                    content: { height: '100svh', width: '100svw', backgroundColor: '#FFF', overflow: 'auto' },
                    header: { backgroundColor: token.colorPrimary, fontSize: 24, color: '#FFF' }
                }}
                destroyOnClose
                getContainer={getContainer}
                height={'100vh'}
            >
                <BoardingPass
                    loading={loading}
                    error={error}
                    booking={booking}
                    airports={airports}
                    fn={selectedTicket?.firstName}
                    ln={selectedTicket?.lastName}
                    direction={validationDirection}
                />
            </Drawer>
        </>
    )
}
