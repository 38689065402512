import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Radio, RadioChangeEvent, Spin } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContextSelector } from 'use-context-selector';
import { AppContext, AppContextType } from 'AppContext';
import FlightInformation from "../FlightInfrormation";
import { Drawer } from "../Drawer";
import OutboundInfo from 'components/OutboundInfo';
import { Direction, Pax } from "../../utils/booking";
import { STEPS } from 'utils/utilits';
import { FlightV2, Route } from 'utils/flight';
import './BoardingPassDriver.css'


interface BoardingPassDriverProps {
    nextStep: string,
    step?: string,
    onChangeDirection?: (direction: Direction) => void
}

function BoardingPassDriver({ nextStep, step, onChangeDirection }: BoardingPassDriverProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { getFlights, clear } = useContextSelector(AppContext, (c: AppContextType) => c.flight);
    const bookingDirectFlight = useContextSelector(AppContext, (c: AppContextType) => c.booking);
    const bookingBackFlight = useContextSelector(AppContext, (c: AppContextType) => c.bookingBackFlight);
    const flights = useContextSelector(AppContext, (c: AppContextType) => c.flight.flights);
    const { seats, clearSeats } = useContextSelector(AppContext, (c: AppContextType) => c.seats);
    const [showChoiceTransitBack, setShowChoiceTransitBack] = useState<boolean>(false);
    const [direction, setDirection] = useState<Direction>(Direction.Forward);
    const [backFlightLoading, setBackFlightLoading] = useState<boolean>(true);

    const {
        date,
        departure,
        arrival,
        counts,
        passengers,
        flight,
        showDrawer,
        setDate,
        setPassengers,
        setFlight,
        setShowDrawer,
        isOneWay
    } = useMemo(() => {
        if (direction === Direction.Forward) {
            return bookingDirectFlight;
        } else {
            return bookingBackFlight;
        }
    }, [direction, bookingBackFlight, bookingDirectFlight]);

    const [
        seatedPassengers, setSeatedPassengers
    ] = useState<Record<'adults' | 'childs' | 'infants', Pax[]>>(passengers);

    useEffect(() => {
        if (flights.length === 1) {
            if (step === STEPS.launch) {
                bookingDirectFlight.setFlight(flights[0]);
            } else if (step === STEPS.outbound && direction === Direction.Back)
                bookingBackFlight.setFlight(flights[0]);
        }
    }, [bookingBackFlight, step, bookingDirectFlight, direction, flights, setFlight]);

    useEffect(() => {
        if (location.pathname === '/') {
            clearSeats();
            bookingDirectFlight.clear();
            bookingBackFlight.clear();
            setShowChoiceTransitBack(false);
        }
    }, [location.pathname]);

    useEffect(() => {
        const passengers = { adults: [], childs: [], infants: [] };
        Object.keys(seats).forEach((col: string) => {
            Object.keys(seats[col]).forEach((row: string) => {
                const val = seats[col][row];
                if (val.flag) {
                    // @ts-ignore
                    passengers[val.type + 's' as 'adults' | 'childs' | 'infants'].push({
                        type: val.type as 'adult' | 'child' | 'infant',
                        seat_number: val.seatNumber as string,
                        flyClass: val.flyClass,
                    });
                }
            })
        });
        setSeatedPassengers(passengers);
    }, [seats]);

    useEffect(() => {
        let seatsReady: number[] = [];

        Object.keys(seats).forEach((col: string) => {
            Object.keys(seats[col]).forEach((row: string) => {
                const val = seats[col][row];
                if (val.flag) {
                    seatsReady.push(val.id as number);
                }
            })
        });
        if (step === STEPS.outbound &&
            seatsReady.length > 0 &&
            seatsReady.length === (counts.adults + counts.childs + counts.infants)
        ) {
            setShowDrawer(true);
        }
        if (step === STEPS.options) {
            setShowDrawer(true);
        }
    }, [step, seats, date, counts, flights]);

    const titles = useMemo(() => {
        switch (step) {
            case STEPS.launch:
                return isOneWay ? t('LaunchScreen.selectedFlight') : t('LaunchScreen.selectedTransitFlight', {
                    departure: departure?.name,
                    arrival: arrival?.name,
                    direction
                })
            case STEPS.outbound: {
                if (showChoiceTransitBack) {
                    return t('LaunchScreen.selectedTransitFlight', {
                        departure: departure?.name,
                        arrival: arrival?.name,
                        direction
                    })
                } else {
                    return t('OutboundDurationCard.selectedSeat', {
                        departure: departure?.name,
                        arrival: arrival?.name,
                        direction
                    })
                }
            }
            case STEPS.options:
                return t('OptionsScreen.titleDrawer')
            default:
                return
        }
    }, [step, t, departure, showChoiceTransitBack, arrival, direction, isOneWay]);

    const handleClose = useCallback(() => {
        setShowDrawer(false);
        if (step === STEPS.launch) {
            setDate(null);
            setFlight(null);
        }
    }, [step, setDate, setFlight]);

    const initBackFlight = useCallback(async () => {
        bookingBackFlight.setDeparture(bookingDirectFlight.arrival);
        bookingBackFlight.setArrival(bookingDirectFlight.departure);
        bookingBackFlight.setCounts(bookingDirectFlight.counts);
        bookingBackFlight.setShowDrawer(true);

        setBackFlightLoading(true);
        setShowChoiceTransitBack(true);
        const flights = await getFlights({
            origin: bookingDirectFlight.arrival?.iata ?? '',
            destination: bookingDirectFlight.departure?.iata ?? ''
        });
        setBackFlightLoading(false);
        console.log('[BoardingPassDriver].handleBackFlight', flights);
    }, [bookingBackFlight, bookingDirectFlight]);

    const handleContinue = useCallback(() => {
        const wrapper = document.getElementById('Wrapper');

        if (step === STEPS.outbound && bookingDirectFlight.isOneWay) {
            setShowDrawer(false);
            wrapper?.scrollIntoView({ behavior: 'auto', block: "start" });
            setPassengers(seatedPassengers, () => null);
            navigate(nextStep);
            return;
        } else if (step === STEPS.outbound && seatedPassengers && direction === Direction.Forward) {
            setPassengers(seatedPassengers, () => null);
            clearSeats();
            setDirection(Direction.Back);

            if (bookingBackFlight.date && !bookingDirectFlight.date?.isSame?.(bookingBackFlight.date)) {
                initBackFlight().catch(err => console.error('[BoardingPassDriver].handleContinue', err));
            }
            if (onChangeDirection) {
                onChangeDirection(Direction.Back);
            }
            // setShowDrawer(false);
            return;
        } else if (step === STEPS.outbound && seatedPassengers && direction === Direction.Back && showChoiceTransitBack) {
            setShowDrawer(false);
            setShowChoiceTransitBack(false);
            // navigate(nextStep);
            return;
        } else if (step === STEPS.outbound && seatedPassengers && direction === Direction.Back) {
            setPassengers(seatedPassengers, () => null);
            clearSeats();
        }

        setShowDrawer(false);
        wrapper?.scrollIntoView({ behavior: 'auto', block: "start" });
        navigate(nextStep);
    }, [navigate, nextStep, step, direction, seatedPassengers, initBackFlight, showChoiceTransitBack, setPassengers, clearSeats]);

    const onFlightSelect = useCallback((e: RadioChangeEvent) => {
        console.log('===========SELECT_FLIGHT', e.target.value)
        setFlight(e.target.value);
    }, [setFlight]);

    const flightDate = useMemo(() => {
        if (date) {
            return date;
        } else {
            return null;
        }
    }, [date])

    const noShowLoading: boolean = useMemo(() => {
        if (showChoiceTransitBack) {
            return true;
        }

        // if (direction === Direction.Back) {
        //     return true;
        // }
        return false;
    }, [showChoiceTransitBack]);

    useEffect(() => {
        return () => {
            clear()
        }
    }, []);
    
    const renderFlight = useCallback((flight: FlightV2, index: number) => {
        return (
            <Radio key={index} value={flight}>
                <FlightInformation
                    routes={flight.route as Route[]}
                    originCountryName={departure?.country}
                    destinationCountryName={arrival?.country}
                    date={flightDate}
                    counts={{
                        adults: counts.adults,
                        childs: counts.childs
                    }}
                    passengers={seatedPassengers}
                    mode='dark'
                    showTime
                    type={direction === Direction.Forward ? 'out' : 'in'}
                />
            </Radio>
        )
    }, [seatedPassengers, arrival?.country, counts.adults, counts.childs, flightDate, departure?.country, direction]);

    return (
        <Drawer
            open={showDrawer}
            title={titles}
            onClose={step === STEPS.options || (direction === Direction.Back && showChoiceTransitBack) ? undefined : handleClose}
            onContinue={handleContinue}
            noShowLoading={noShowLoading}
            disabled={!flight}
            mask={step !== STEPS.options}
        >
            {
                step === STEPS.launch ? (
                    <Radio.Group value={flight} onChange={onFlightSelect}>
                        {step !== STEPS.launch && flight ? [flight].map(renderFlight) : flights.map(renderFlight)}
                    </Radio.Group>
                ) : step === STEPS.outbound ? (
                    showChoiceTransitBack ? (
                        backFlightLoading ? (
                            <Flex style={{ minHeight: 250 }} justify='center' align='center'>
                                <Spin size='large' />
                            </Flex>
                        ) : (
                            <Radio.Group value={flight} onChange={onFlightSelect}>
                                {flights.map(renderFlight)}
                            </Radio.Group>
                        )
                    ) : (
                        <OutboundInfo
                            flight={flight as FlightV2}
                            date={date}
                            counts={{
                                adults: counts.adults,
                                childs: counts.childs
                            }}
                            passengers={seatedPassengers}
                            mode='dark'
                        />
                    )
                ) : (
                    null
                )
            }
        </Drawer>
    );
}

export default BoardingPassDriver;
